import React from 'react';
import Layout from "../components/Layout";
import withTrans from '../i18n/withTranslation';


const TermsPage = ({ data, t }) => {

  const getContent = (translation) => {
    return { __html: `${t(translation)}` }
  }

  return (
    <>
      <Layout
        title={t('site.pages.TermsPage.meta.title')}
        description={t('site.pages.TermsPage.meta.description')}
      >

        <div className="container my-5" style={{ paddingTop: '80px' }}>
          <h1 className="mb-5 text-center">{t('site.pages.TermsPage.meta.title')}</h1>

          <div dangerouslySetInnerHTML={getContent('site.pages.TermsPage.content')}></div>

        </div>
      </Layout>
    </>
  )
}

export default withTrans(TermsPage);